<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <div class="home">
        <v-row class="text-center">
          <v-col>
            ManageNote
            <h2 class="display-1 font-weight-light mb-3" v-html="heading"></h2>
            {{ note }}
          </v-col>
        </v-row>
        <v-row>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="note.title"
                    label="Title"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="note.type"
                    :items="types"
                    label="Type"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="note.description"
                    label="Description"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="note.duedate"
                        label="Due date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="note.duedate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-btn @click="updateNote()" color="primary">Save</v-btn>

              </v-row>
            </v-container>
          </v-form>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { notesRef } from "../store/firestore";
import * as moment from "moment/moment";
import router from "../router";
import store from "../store";

//  this.$forceUpdate(); is nog een probleem
//  click op v-card ook.
//  ui van de chat
//  delete message
//  conversationNEW naar conversation?
//  gelezen of niet en notificatie
export default {
  name: "ManageNote",

  data: () => ({
    heading: "Manage Note",
    note: {},
    types: ["Donation", "Activity", "Facilitator"],
    menu2: false,
    conversationId: "",
    valid: false,
    titleRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 20 || "Name must be less than 10 characters",
    ],
    // email: '',
    // emailRules: [
    //   v => !!v || 'E-mail is required',
    //   v => /.+@.+/.test(v) || 'E-mail must be valid',
    // ],
  }),
  created() {
    if (this.$route.params.id) {
      this.getData(this.$route.params.id);
    } else {
      this.heading = "Add note";
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD-M-YYYY");
    },
    momentTime: function (date) {
      return moment(date).format("HH:mm op DD-M-YYYY");
    },
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    imageSrc() {
      // return require("../assets/cards/" + this.randomItem(this.images));
    },

    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
    updateNote() {
      var that = this;
      if(! that.note.id){
       notesRef.add(that.note) 
       .then(function () {
          router.push(/note/ + that.note.id);
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
      }
      else{
        var noteRef = notesRef.doc(that.note.id);
        noteRef.set(
            that.note,
            { merge: true }
          )
          .then(function () {
            router.push(/note/ + that.note.id);
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      }
    },
    cancelWalk(partnerWalk) {
      // huidige wandeling zit op scope this.walk
      // zet de variable "partnerFound = false"
      // zet de variabe "partner = leeg" op beide wanderlingen
      var that = this;
      var userWalk = this.note;
      var partnerWalkRef = notesRef.doc(partnerWalk.id);
      var userWalkRef = notesRef.doc(userWalk.id);
      partnerWalkRef
        .set(
          {
            partnerFound: false,
            partner: {},
          },
          { merge: true }
        )
        .then(function () {
          that.changeCounter(-1, that.user.data.uid);
          userWalkRef
            .set(
              {
                partnerFound: false,
                partner: {},
              },
              { merge: true }
            )
            .then(function () {
              //gelukt
              that.changeCounter(-1, partnerWalk.uid);
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
    },

    toggleEl(match) {
      match.isActive = !match.isActive;
      this.$forceUpdate();
    },
    goToChat(matchUID, walkerUID, walkId) {
      this.conversationId = walkerUID + matchUID;
      if (matchUID < walkerUID) {
        this.conversationId = matchUID + walkerUID;
      }
      store.dispatch("setLastWalk", walkId);
      router.push(/chat/ + this.conversationId);
    },

    getData(id) {
      let that = this;
      var singleWalk = notesRef.doc(id);

      singleWalk.onSnapshot(function (doc) {
        if (doc.exists) {
          let data = doc.data();
          that.note = data;
          that.note.id = id;
          that.heading = data.title;

          //that.findMatches(data.datum);
        } else {
          //console.log("No such document!");
        }
      });
    },
    filterByValue(array, string) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toLowerCase().includes(string.toLowerCase())
        )
      );
    },
    removeBookedBYPartner(matchedWalker) {
      return matchedWalker.partnerFound !== true;
    },
    filterByValueToo(array, string) {
      return array.filter((o) => {
        return Object.keys(o).some((k) => {
          if (typeof o[k] === "string")
            return o[k].toLowerCase().includes(string.toLowerCase());
        });
      });
    },
    findMatches(datum) {
      var that = this;
      var test = [];
      test = this.filterByValueToo(this.matches, datum); // ontdubbelen
      function removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      }

      var uniqueArray = removeDuplicates(test, "id");
      that.matchedWalkers = uniqueArray.filter(this.removeBookedBYPartner);
    },
  },
};
</script>